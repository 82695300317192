.rhap_container {
  box-shadow: none;
  background-color: transparent;
  padding-right: 8px;
}

.rhap_main.rhap_horizontal-reverse .rhap_controls-section {
  flex: 0;
}

.rhap_download-progress {
  background-color: #b9b9b9;
}

.rhap_progress-section {
  flex-wrap: wrap;
  justify-content: space-between;
}
.rhap_progress-container {
  min-width: 95%;
  margin-bottom: 8px;
  margin-top: 8px;
}
.rhap_time {
  font-size: 12px;
}
.rhap_current-time {
  margin-left: 10px;
}
